<template>
  <div class="container">
    <section>
      <v-row class="d-flex justify-space-between align-center">
        <v-col
          cols="12"
          md="7"
          class="d-flex flex-column justify-start align-center"
        >
          <Card
            borderColor="#e06919"
            class="mb-5"
            style="z-index: 2;"
            title="Cultura"
            titleColor="#000"
          >
            <template v-slot:conteudo>
              <p>
                A culinária local segue os pratos típicos mineiros e as
                <router-link
                  tag="a"
                  style="text-decoration: none"
                  class="pink--text"
                  :to="{ name: 'conteudo_soberania_quintandas' }"
                  >quitandas</router-link
                >, a exemplo das rosquinhas de amoníaco, servidas com café. Como
                tradição culinária, são feitas também as amêndoas de coroação de
                Nossa Senhora, produzidas no mês de maio.
              </p>
              <p>
                Monsenhor Horta possui uma cultura musical expressiva, a exemplo
                da Sociedade Musical São Caetano, do Coral São Caetano e
                Orquestra São Caetano. O gosto pela música é transmitido entre
                as gerações, fazendo assim parte dos grupos tanto antigos
                moradores quanto jovens. Entre as atividades e práticas
                tradicionais de Monsenhor Horta, ressalta-se a extração de ouro,
                que assim como nas demais localidades da região de Mariana,
                desde o século XVIII tem o garimpo como atividade socioeconômica
                de grande relevância para o desenvolvimento local.
              </p>
            </template>
          </Card>
        </v-col>
        <v-col
          cols="12"
          md="5"
          class="d-flex justify-center align-center flex-column"
        >
          <v-img
            max-width="100%"
            src="../../assets/sobre_cultura_monsenhor_01.png"
          >
          </v-img>
          <p style="font-size: 0.6em; width: 100%; text-align: center">
            A Igreja de São Caetano reúne fiéis em torno das festividades
            religiosas. Foto: Wan Campos, 2017.
          </p>
        </v-col>
      </v-row>
    </section>
    <section>
      <v-row class="d-flex justify-space-between align-center">
        <v-col
          cols="12"
          md="5"
          class="d-flex justify-end align-start flex-column"
        >
          <v-img
            max-width="100%"
            src="../../assets/sobre_cultura_monsenhor_02.png"
          >
          </v-img>
          <p style="font-size: 0.6em; width: 100%; text-align: center">
            Procissão festiva em honra a Nossa Senhora Aparecida. Foto: Wan
            Campos, 2017.
          </p>
        </v-col>
        <v-col
          cols="12"
          md="7"
          class="d-flex flex-column justify-start align-center"
        >
          <Card borderColor="#e06919" class="mb-5" style="z-index: 2;">
            <template v-slot:conteudo>
              <p>
                Conforme relatado por garimpeiros que atuam na região de Mariana
                nos tempos atuais, a atividade foi sendo transmitida de geração
                em geração, há muitos anos utilizando-se bateias em busca do
                ouro nas margens de rios e córregos. Desde muito novos,
                acompanhavam os pais e assim foram aprendendo e criando vínculos
                com o garimpo artesanal. Parte dos garimpeiros, com ênfase para
                moradores de Monsenhor Horta, dedicaram-se à atividade tendo
                nela sua principal fonte de renda. Outros, por sua vez,
                permaneceram garimpando e mantendo a tradição paralelamente a
                outros ofícios, como complementação da renda da família.
              </p>
              <p>
                Além da extração de ouro, o distrito possui como atividades
                econômicas o cultivo de eucalipto e produção de carvão, lavouras
                de milho, arroz e feijão.
              </p>
            </template>
          </Card>
        </v-col>
      </v-row>
    </section>
    <section>
      <v-row class="d-flex justify-space-between align-center">
        <v-col
          cols="12"
          md="7"
          class="d-flex flex-column justify-start align-center"
        >
          <Card
            borderColor="#e06919"
            class="mb-5"
            style="z-index: 2;"
            title="Manifestações culturais tradicionais:"
            titleColor="#000"
          >
            <template v-slot:conteudo>
              <p>
                O distrito de Monsenhor Horta, além de sua religiosidade
                praticada desde o período colonial e mantida como tradição,
                possui a música entre seus costumes. A centenária sociedade
                musical de São Caetano é ainda atuante, tratando-se da 4ª Banda
                musical mais antiga do Brasil, a 3ª de Minas Gerais e a mais
                antiga da Região dos Inconfidentes. Os ensaios ocorrem aos
                sábados e estão vinculados a festividades tradicionais no
                distrito, a exemplo da Festa do Vinho.
              </p>
              <p>
                Entre os saberes tradicionais, destaca-se a produção artesanal
                de balaio, esteira de taquara, esculturas, bordados, tricô,
                crochê, comidas típicas, amêndoas de Coroação de Nossa Senhora,
                rosquinhas de amoníaco, etc. As celebrações religiosas acontecem
                na Igreja Matriz de São Caetano, estando entre elas a Festa do
                Padroeiro São Caetano e as demais que podem ser acompanhadas no
                <router-link
                  tag="a"
                  style="text-decoration: none"
                  class="pink--text"
                  :to="{ name: 'conteudos_tematicos_religiosidade_calendario' }"
                  >calendário litúrgico</router-link
                >.
              </p>
              <p>
                Cabe destacar a Missa de Réquiem, que havia deixado de ser
                celebrada e foi retomada em 02 de novembro de 2019.
              </p>
            </template>
          </Card>
        </v-col>
        <v-col
          cols="12"
          md="5"
          class="d-flex justify-center align-center flex-column"
        >
          <v-img
            max-width="100%"
            src="../../assets/sobre_cultura_monsenhor_03.png"
          >
          </v-img>
          <p style="font-size: 0.6em; width: 100%; text-align: center">
            Exterior da Igreja Matriz de São Caetano. Foto: Plínio Lins, 2016.
          </p>
        </v-col>
      </v-row>
    </section>
    <section>
      <v-row class="d-flex justify-space-between align-center">
        <v-col
          cols="12"
          md="5"
          class="d-flex justify-end align-start flex-column"
        >
          <v-img
            max-width="100%"
            src="../../assets/sobre_cultura_monsenhor_04.png"
          >
          </v-img>
          <p style="font-size: 0.6em; width: 100%; text-align: center">
            Vista do interior da Igreja Matriz de São Caetano. Foto: Plínio
            Lins, 2016.
          </p>
        </v-col>
        <v-col
          cols="12"
          md="7"
          class="d-flex flex-column justify-start align-center"
        >
          <Card
            borderColor="#e06919"
            class="mb-5"
            style="z-index: 2;"
            title="Relato sobre a Missa de Réquiem"
            titleColor="#000"
          >
            <template v-slot:conteudo>
              <p>
                Celebração que possui 300 anos e deixou de acontecer durante 40
                anos, sendo celebrada novamente em 02 de novembro de 2019, com
                cânticos originais em latim, descobertos no arquivo do Coral São
                Caetano, e paramentos ancestrais como casula romana preta,
                barrete, e capa de asperge preta.
              </p>
              <p>
                Do latim réquiem, que significa “descanso”, a celebração incluiu
                a encomendação no cemitério, em devoção às Santas Almas do
                Purgatório. A procissão seguia em direção ao cemitério, com
                aspersão de água benta nos túmulos. No século XVIII, existia no
                distrito a Irmandade de São Miguel das Almas, que recomendava a
                missa pelas almas sempre, especialmente às segundas feiras. A
                missa era toda conduzida pela Irmandade de São Miguel e Almas do
                Purgatório. Quando morria alguém no distrito, toda a irmandade
                ia buscar o corpo, trajada com a opa verde, portando cruz e
                lanternas para levá-lo até o cemitério. Representando a
                Irmandade de São Miguel foram feitas opas verdes seguindo o
                modelo original, sendo essas usadas na retomada da celebração
                por moradores do distrito e convidados durante a missa. A antiga
                celebração foi resgatada conforme pesquisa realizada por Arthur
                Ramos Carneiro, natural do distrito, em um arquivo do Museu da
                Inconfidência, em Ouro Preto, tratando-se do Livro de
                Compromisso da Irmandade de São Miguel e Almas do Purgatório,
                datado de 1722.
              </p>
            </template>
          </Card>
        </v-col>
      </v-row>
    </section>
    <section>
      <v-row class="d-flex justify-space-between align-center">
        <v-col
          cols="12"
          md="7"
          class="d-flex flex-column justify-start align-center"
        >
          <Card
            borderColor="#e06919"
            class="mb-5"
            style="z-index: 2;"
            title="A técnica das Amêndoas de Coroação"
            titleColor="#000"
          >
            <template v-slot:conteudo>
              <p>
                Em todas as celebrações de Coroação de Nossa Senhora no mês de
                maio, são distribuídos embrulhos com o doce ao final. Apesar da
                produção das amêndoas ter passado por um período em que menos
                pessoas se dedicavam ao seu feitio, os moradores locais vêm
                retomando a tradição. A distribuição era direcionada
                primeiramente às crianças que participavam da coroação, com suas
                vestes de anjos e, havendo excedente, as outras crianças também
                recebiam.
              </p>
              <p>
                O preparo é feito com a receita que leva 500g de amendoim, 1kg
                de açúcar cristal, 500ml de água viva, erva doce a gosto, canela
                em rama a gosto, cravo a gosto, 100g de açúcar refinado,
                “cartucho” (cone) de papel para colocar as amêndoas.
              </p>
            </template>
          </Card>
        </v-col>
        <v-col
          cols="12"
          md="5"
          class="d-flex justify-center align-center flex-column"
        >
          <v-img
            max-width="100%"
            src="../../assets/sobre_cultura_monsenhor_05.png"
          >
          </v-img>
          <p style="font-size: 0.6em; width: 100%; text-align: center">
            Retábulos e púlpito no interior da Igreja Matriz de São Caetano.
            Foto: Plínio Lins, 2016.
          </p>
        </v-col>
      </v-row>
    </section>
    <section>
      <v-row class="d-flex justify-space-between align-center">
        <v-col
          cols="12"
          md="5"
          class="d-flex justify-center align-center justify-md-end flex-column"
        >
          <v-img
            max-width="100%"
            src="../../assets/sobre_cultura_monsenhor_06.png"
          >
          </v-img>
          <p style="font-size: 0.6em; width: 100%; text-align: center">
            Detalhe de um dos púlpitos presentes no interior da Igreja Matriz de
            São Caetano. Foto: Plínio Lins, 2016.
          </p>
        </v-col>
        <v-col
          cols="12"
          md="7"
          class="d-flex flex-column justify-start align-center"
        >
          <Card
            borderColor="#e06919"
            class="mb-5"
            style="z-index: 2;"
            title="Atividades Recreativas"
            titleColor="#000"
          >
            <template v-slot:conteudo>
              <p>
                Moradores e visitantes se divertem nas águas do local conhecido
                como “Ponte das Crioulas” para nadar, pescar e acampar. O local
                é tranquilo em meio à mata, onde na clareira surge uma piscina
                natural depois da queda d’água. Às margens do curso d’água,
                encontra-se uma área espraiada com vegetação rasteira que
                possibilita banhos de sol e instalação de barracas para
                acampamento. Situa-se na estrada que conduz ao subdistrito de
                Ponte do Gama, a 7 km de Monsenhor Horta.
              </p>
            </template>
          </Card>
        </v-col>
      </v-row>
    </section>
    <section>
      <v-row class="d-flex justify-space-between align-center">
        <v-col
          cols="12"
          md="7"
          class="d-flex flex-column justify-center align-center"
        >
          <Card
            borderColor="#e06919"
            class="mb-5"
            title="Igreja Matriz de São Caetano"
            titleColor="#000"
          >
            <template v-slot:conteudo>
              <p>
                Por meio de um documento datado de 1730, sabe-se que uma
                irmandade decidiu erguer o templo pelo preço de 3.800 oitavas de
                ouro. Considerando-se a aparência de quase todas as matrizes
                mineiras, é bastante provável que tenha sido a Irmandade do
                Santíssimo Sacramento a tomar essa iniciativa. O mesmo documento
                menciona três artistas que teriam trabalhado nas obras, sendo
                eles o ferreiro João Pereira Borges, o mestre carpinteiro Luis
                Antonio Pereira, e Manuel Martins, indicando que para a
                construção da matriz foi contratada mão de obra bastante
                qualificada.
              </p>
              <p>
                A igreja ainda estava inacabada quando, em 1742, foi criada uma
                freguesia (paróquia) para o local. Por meio de um alvará emitido
                pelo rei de Portugal datado de 1752 foi a ela concedida a
                condição de “Colatina”, ou seja, com um vigário fixo.
                Parcialmente finalizada em 1742, as obras e trabalhos de
                decoração se estenderam ao longo dos séculos XVIII e XIX.
              </p>
            </template>
          </Card>
          <Card borderColor="#e06919" class="mb-5 mt-5">
            <template v-slot:conteudo>
              <p>
                Externamente a matriz é simples, com aspecto robusto (pesado),
                com duas torres baixas e sem muitos elementos decorativos,
                exceto por uma pequena cimalha esculpida acima da portada, com
                data de 1797. Os sinos são mais antigos, e um deles
                provavelmente foi fundido em 1747.
              </p>
              <p>
                Diferente da simplicidade externa, o interior dessa igreja é
                ricamente ornamentado, possuindo cinco belíssimos retábulos e
                dois púlpitos, de autoria indefinida, e que seguem o estilo
                joanino. O estilo Barroco Joanino é uma fase do Barroco
                Português que prosperou durante o reinado de D. João V.
                Importantes exemplos de retábulos e outros elementos integrados
                de monumentos religiosos são encontrados tanto em Portugal como
                no Brasil. Isso demonstra a importância que a Matriz de São
                Caetano possui para o Brasil, além da representatividade para o
                Estado de Minas Gerais, para o município de Mariana, bem como
                para o distrito de Monsenhor Horta e sua população.
              </p>
            </template>
          </Card>
        </v-col>
        <v-col
          cols="12"
          md="5"
          class="d-flex flex-column justify-center align-center"
        >
          <v-img
            max-width="100%"
            src="../../assets/sobre_cultura_monsenhor_07.png"
            alt=""
          ></v-img>
          <v-img
            class="mt-3"
            max-width="100%"
            src="../../assets/sobre_cultura_monsenhor_08.png"
            alt=""
          ></v-img>
          <p style="font-size: 0.6em; width: 100%; text-align: center">
            Retábulos no estilo barroco Joanino no interior da Igreja Matriz de
            São Caetano. Foto: Plínio Lins, 2016.
          </p>
        </v-col>
      </v-row>
    </section>

    <section>
      <v-row class="d-flex justify-space-between align-center">
        <v-col
          cols="12"
          md="5"
          class="d-flex flex-column justify-center align-center"
        >
          <v-img
            max-width="100%"
            src="../../assets/sobre_cultura_monsenhor_09.png"
            alt=""
          ></v-img>
          <p style="font-size: 0.6em; width: 100%; text-align: center">
            Detalhe do arco do altar-mor da Igreja Matriz de São Caetano. Foto:
            Plínio Lins, 2016.
          </p>
          <v-img
            class="mt-3"
            max-width="100%"
            src="../../assets/sobre_cultura_monsenhor_10.png"
            alt=""
          ></v-img>
          <p style="font-size: 0.6em; width: 100%; text-align: center">
            Detalhe do arco de um dos retábulos da Igreja Matriz de São Caetano.
            Foto: Plínio Lins, 2016.
          </p>
        </v-col>
        <v-col
          cols="12"
          md="7"
          class="d-flex flex-column justify-center align-center"
        >
          <Card borderColor="#e06919" class="mb-5">
            <template v-slot:conteudo>
              <p>
                Outra fonte tomada como base para se entender as datas de
                construção da igreja, é um documento de 1775 contendo despesas
                com douramento e pintura, dando a entender que já estavam
                finalizados nesta época. O altar-mor possui dossel e
                arquivoltas, e também colunas torcidas com enfeites de animais e
                plantas. Os altares laterais, em harmonia com o retábulo
                principal, possuem colunas torsas, várias conchas, além do trono
                em forma de vaso. O arco cruzeiro também é bastante trabalhado,
                com talha semelhante à das paredes da capela mor.
              </p>
              <p>
                A planta (base ou risco) é organizada seguindo o esquema típico
                das construções da primeira metade do século XVIII, com nave e
                capela-mor tendo ao lado corredores, e sacristia localizada em
                sua parte posterior.
              </p>
              <p>
                Lateralmente à capela-mor, em um dos corredores, foi incluída a
                Capela do Santíssimo, construída em época posterior à da Igreja.
                No interior da matriz, encontra-se sepultado o fundador do
                local, o bandeirante Salvador Fernandes Furtado de Mendonça. Em
                1953, a Igreja Matriz de São Caetano foi protegida por
                tombamento Federal, ou seja, foi incluída como patrimônio do
                Brasil, e qualquer obra nela realizada precisa ser feita com
                muito cuidado, utilizando-se os mesmos materiais que foram
                empregados na sua construção original, entre outros cuidados
                para que esse Bem Cultural seja mantido e que as novas gerações
                possam conhecê-la, admirá-la.
              </p>
              <p style="text-align: center">
                <strong>Autora: Ana Paula Alves Ferreira<sup>1</sup></strong>
              </p>
            </template>
          </Card>
        </v-col>
      </v-row>
    </section>
    <section class="table__section">
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" class="d-flex flex-column justify-center align-center">
          <div class="inside__card">
            <p style="margin-bottom: 0;">
              <sup>1</sup> Arquiteta e Urbanista pela USP. Assessora Técnica na
              ATI Mariana.
            </p>
          </div>
        </v-col>
      </v-row>
    </section>
  </div>
</template>

<script>
import Card from "@/components/Card";
export default {
  components: { Card },
  data() {
    return {};
  },
};
</script>

<style scoped>
.green__stripe {
  position: relative;
}

.green__stripe:before {
  content: "";
  position: absolute;
  top: 30%;
  left: 0;
  height: 110px;
  width: 200%;
  background-color: #04441f;
}
h6 {
  font-size: 0.8em;
}

section {
  margin-bottom: 3em;
}
.position-rel {
  position: relative;
}

.position-abs {
  position: absolute;
}

.table__section {
  margin-top: 3em;
  margin-bottom: 3em;
}

.inside__card {
  background-color: white;
  width: 100%;
  padding: 1em;
  border-radius: 8px;
}

.inside__card p {
  font-size: 0.5em;
}
</style>
